import React, { useRef, useState } from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import Intro from '../components/design-grafico/intro'
import Icons from '../components/icons'
import PropTypes from 'prop-types';
import Cursor from '../components/cursor'
import ImageHero from '../components/design-grafico/image-hero';
import { LocomotiveScrollProvider } from 'react-locomotive-scroll'
import { Link } from "gatsby"

function DesignGraficoIndex({ data }) {

	const [modalVisible, setIsShowing] = useState(false);

	function updateStateModal() {
		setIsShowing(!modalVisible);
	}

	
	const containerRef = useRef(null)

	const posts = [...data.withOrder.edges, ...data.withoutOrder.edges];
	const refs = useRef([]);

	return (
		<>
			<LocomotiveScrollProvider options={{ smooth: true, tablet: { smooth: true }, smartphone: { smooth: false }}} watch={[]} containerRef={containerRef}>
				<main id="top" data-scroll-container ref={containerRef}>
					<Layout type="design-grafico" left="designer gráfico" showreelModal={updateStateModal} >
						<Intro />

						<section id="design-grafico" className="p-relative max-width d-flex">
							<div className="row">

								{posts.map(({ node }, i) => {
									return (
										<Link key={i} ref={refs.current[i]} id={node.folder} to={`/portfolio/${node.slug}/`} className="col-xs-12 col-sm-6 portfolio-item ball-js animated" data-scroll>
											<div className="p-relative portfolio-item--hover">
												<ImageHero foldername={node.folder} />
											</div>
											<div className="item-details col">
												<span className="number">#{('000' + (i + 1)).slice(-3)}</span>
												<div className="p-relative d-flex flex-grow">
													<div className="item-title">{node.title}</div>
													<div className="textarea-lines">
														<div className="item-line"></div>
													</div>
												</div>
												<div className="item-description">{node.tags}</div>
											</div>
										</Link>
									)
								})}

							</div>
						</section>
						<Icons />
					</Layout>

				</main>
			</LocomotiveScrollProvider>
			<Cursor />
		</>

	)
}

DesignGraficoIndex.propTypes = {
	data: PropTypes.object.isRequired,
};
export default DesignGraficoIndex


export const pageQuery = graphql`
query DesignGraficoIndexQuery {
	withOrder:allContentfulDesignGrafico(
		filter: { order: { ne: null } },
		 sort: {fields: order, order: DESC}) {
			 edges {
				 node {
					 title
					 slug
					 tags
					 folder
					 order
				 }
			}
	 }
	 withoutOrder:allContentfulDesignGrafico(
		filter: { order: { eq: null } },
		 sort: {fields: order, order: DESC}) {
			 edges {
				 node {
					 title
					 slug
					 tags
					 folder
					 order
				 }
			}
	 }
	}
		`
