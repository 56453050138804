import React from "react";


const Intro = () => {

	return (
		<section id="intro" className="intro p-relative max-width">
			<div className="row pb-100">
				<div className="col-md-offset-7 col-md-5 col-xs-12 pt-100">
					<div className="row">
						<div className="col-xs-12">
							<div className="logotipos-about">
								<p className="leading-text leading-text--position">
									olá<br /><br />
									este é o meu portfólio<br />
									de<span className="leading-text--effect"> design gráfico...</span><br />
									pensado à mão.<br />
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>

	)
};
export default Intro