import React from 'react';
import PropTypes from 'prop-types';

import { graphql, useStaticQuery } from "gatsby"


const ImageHero = (props) => {

	const data = useStaticQuery(
		graphql`query {
			images: allFile(
				filter: {sourceInstanceName: {eq: "designer-grafico"}, base: {regex: "/Cover/"}}
				sort: {order: ASC, fields: relativePath}
			) {
				edges {
					node {
						relativePath
						base
					}
				}
			}
}
`)


	const galleryImages = data.images.edges.filter(edge =>
		edge.node.relativePath.startsWith(props.foldername)
	)

	let image;
	if(galleryImages[0]) { image = "/portfolio/" + galleryImages[0].node.relativePath; }

	return (
		<img className="portfolio-image" src={image} style={{ width: "100%" }}></img>
	)
}


ImageHero.propTypes = {
	foldername: PropTypes.string,
	bgColor: PropTypes.string
};

export default ImageHero;